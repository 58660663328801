/* eslint-disable react/jsx-props-no-spreading */
import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Form, FormikContext, useFormik,
} from 'formik';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Input from '../Common/Form/Input';
import { InventoryTaskQueryParams } from '../../interfaces/InventoryTaskQueryParams';
import RadioField from '../Common/Form/Radio';
import {
	defaultInventoryTaskQueryParams,
	drawerFilterInventoryPendingTask,
	inventoryTaskStatusFilter,
} from '../../constants/inventoryTask';
import { DatePicker } from '../Common/Form/DatePicker';
import { filterObject } from '../../helpers/Utils';
import { InventoryTaskAction } from '../../enums/InventoryTaskAction';
import { BranchParams } from '../../containers/Inventory/InventoryAssets';
import Autocomplete from '../Common/Form/Autocomplete';
import { IProductWithoutDetails } from '../../containers/Product/ProductAssets';
import { ProductQueryParams } from '../../interfaces/ProductQueryParams';
import { useConditionalSearch } from '../../hooks/useConditionalSearchProps';
import { ILocationProductWithoutProduct, LocationProductWithoutProductQueryParams } from '../../interfaces/LocationProduct';

interface FilterProps {
	handlerValueFilter: (values: Partial<InventoryTaskQueryParams>) => void;
    initialValues: InventoryTaskQueryParams
	getBranches: (params: BranchParams) => void;
	products: IProductWithoutDetails[];
	locationWithoutProducts: ILocationProductWithoutProduct[];
	getProducts: (queryParams: ProductQueryParams) => void;
	getLocationProductWithoutProduct: (params: LocationProductWithoutProductQueryParams) => void;
}

const InventoryPendingTaskFilter = ({
	initialValues,
	products,
	locationWithoutProducts,
	handlerValueFilter,
	getBranches,
	getProducts,
	getLocationProductWithoutProduct,
}: FilterProps): JSX.Element => {
	const [productInputValue, setProductInputValue] = useState('');
	const [locationProductInputValue, setLocationProductInputValue] = useState('');

	const onSubmit = useCallback((values: Partial<InventoryTaskQueryParams>) => {
		const all = '99';
		const filterValues = { ...values, status: String(values.status) === all ? '' : values.status };
		localStorage.setItem('inventoryTaskFilterParams', JSON.stringify(filterValues));
		handlerValueFilter(filterObject(filterValues));
	}, [handlerValueFilter]);

	useConditionalSearch({
		inputValue: productInputValue,
		loadedItems: products,
		searchFunction: getProducts,
		minLength: 3,
		compareKey: 'description',
		compareKeySecondary: 'code',
		queryParam: 'description',
	});

	useConditionalSearch({
		inputValue: locationProductInputValue,
		loadedItems: locationWithoutProducts,
		searchFunction: getLocationProductWithoutProduct,
		minLength: 3,
		compareKey: 'locationBarCode',
		queryParam: 'locationBarCode',
	});

	const formik = useFormik({
		validationSchema: drawerFilterInventoryPendingTask,
		validateOnChange: false,
		initialValues,
		onSubmit,
		validateOnMount: true,
	});

	useEffect(() => {
		if (formik.values.companyId) {
			getBranches({
				companyId: formik.values.companyId,
			});
		}
	}, [formik.values.companyId, getBranches]);

	const onReset = useCallback((resetForm) => {
		handlerValueFilter(filterObject(defaultInventoryTaskQueryParams));
		localStorage.setItem(
			'inventoryTaskFilterParams',
			JSON.stringify(filterObject(defaultInventoryTaskQueryParams)),
		);
		resetForm();
	}, [handlerValueFilter]);

	const isSupplyOrDispath = useMemo(() => {
		const types = [InventoryTaskAction.DISPATCH, InventoryTaskAction.SUPPLY];
		return types.includes(initialValues.type as InventoryTaskAction);
	}, [initialValues.type]);

	return (
		<FormikContext.Provider value={formik}>
			<Form>
				<Grid container spacing={2} sx={{ p: 2, marginTop: 6 }}>
					<Grid container spacing={2} item xs={12}>
						<Grid item xs={6}>
							<DatePicker
								name="startDate"
								label="Data início"
							/>
						</Grid>

						<Grid item xs={6}>
							<DatePicker
								name="endDate"
								label="Data Final"
							/>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Input.InputField
							id="businessPartnerName"
							name="businessPartnerName"
							label="Fornecedor/Cliente"
							autoComplete="off"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<Input.InputField
							id="saleOrder"
							name="saleOrder"
							label="Nº Pedido"
							autoComplete="off"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<Input.InputField
							id="invoiceNumber"
							name="invoiceNumber"
							label="Nº Documento"
							autoComplete="off"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<Autocomplete
							multiple
							limitTags={2}
							name="productIds"
							label="Produto"
							options={products}
							labelKey="description"
							valueKey="id"
							valueLabel="code"
							onInputChange={(event, newInputValue) => setProductInputValue(newInputValue)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Autocomplete
							multiple
							limitTags={2}
							name="locationIds"
							label="Localização"
							options={locationWithoutProducts}
							labelKey="locationName"
							valueLabel="locationBarCode"
							valueKey="locationId"
							formatBarCode
							onInputChange={
								(event, newInputValue) => setLocationProductInputValue(newInputValue)
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<RadioField
							name="status"
							label="Status da Tarefa"
							options={inventoryTaskStatusFilter}
							orientation="column"
						/>
					</Grid>
					<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
						<Button variant="outlined" onClick={() => onReset(() => formik.resetForm())}>
							Restaurar
						</Button>
						<Button variant="contained" type="submit">
							Filtrar
						</Button>
					</Grid>
				</Grid>
			</Form>
		</FormikContext.Provider>
	);
};

export default InventoryPendingTaskFilter;
