import { AxiosResponse } from 'axios';
import api from './api';
import { DanfeByBarCodeQueryParams, IInvoice } from '../containers/Mobile/CheckoutCarrier/CheckoutCarrierAssets';
import { IMessageResponse } from '../interfaces/MessageResponse';
import { IFileStatus, IFiscalDocumentEntriesResponse, IProductsInvoiceResponse } from '../containers/FiscalDocumentEntry/FiscalDocumentEntryAssets';
import {
	FiscalDocumentQueryParams, ILinkProductBusinessPartner, ProductsInvoiceQueryParams,
} from '../interfaces/FiscalDocumentParams';

export const getDanfeByBarCode = (danfeBarCode: string, params: DanfeByBarCodeQueryParams): Promise<
AxiosResponse<IInvoice>> => api.get<IInvoice>(`invoice/danfe/${danfeBarCode}`, { params });

export const confirmInvoiceItems = (invoiceId: string, shippedDate: string): Promise<AxiosResponse<IMessageResponse>> => api.patch(`invoice/check/${invoiceId}`, { shippedDate });

export const deleteInvoice = (id: string): Promise<AxiosResponse<IMessageResponse>> => api.delete<IMessageResponse>(`/invoice/${id}`);

export const uploadXml = (
	data: FormData,
): Promise<AxiosResponse<IFileStatus[]>> => api.post<FormData, AxiosResponse<IFileStatus[]>>('/invoice/upload-xml', data);

export const getProductsInvoice = (
	invoiceId: string,
	params?: ProductsInvoiceQueryParams,
): Promise<
AxiosResponse<IProductsInvoiceResponse>> => api.get<IProductsInvoiceResponse>(`invoice/${invoiceId}`, { params });

export const getFiscalDocuments = (params: FiscalDocumentQueryParams): Promise<
AxiosResponse<IFiscalDocumentEntriesResponse>> => api.get<IFiscalDocumentEntriesResponse>('invoice', { params });

export const linkProductBusinessPartner = (
	data: ILinkProductBusinessPartner[],
): Promise<AxiosResponse<IMessageResponse>> => api.post<ILinkProductBusinessPartner[], AxiosResponse<IMessageResponse>>('/invoice/product-business-partner', data);
