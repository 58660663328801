import Decimal from 'decimal.js';
import React, {
	ChangeEvent,
	useCallback,
	useMemo,
	useState,
} from 'react';
import MUIDataTable, {
	MUIDataTableColumnDef,
	MUIDataTableOptions,
} from 'mui-datatables';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Checkbox } from '@mui/material';
import {
	InventoryTask,
	StatusTaskMap,
	Task,
	TaskProduct,
} from '../../containers/Task/InventoryTaskAssets';
import {
	actionTable,
	columnTransfer,
	countDiffTask,
	createStatusTaskMap,
	defaultFilterContextScreen,
} from '../../constants/inventoryTask';
import { InventoryTaskStatus } from '../../enums/InventoryTaskStatus';
import { SelectedTasks } from './InventoryPendingTask';
import { ConversionType } from '../../enums/ConversionType';
import { formatNumberForLocale } from '../../helpers/NumberUtils';
import { InventoryTaskQueryParams } from '../../interfaces/InventoryTaskQueryParams';
import { InventoryTaskAction } from '../../enums/InventoryTaskAction';
import { columnStorageAndExpedition } from '../../constants/inventoryTaskColumns';
import { generatePDF } from './InventoryPendingTaskPDF';
import { ButtonGroupDropDown } from '../Common/Button/ButtonGroup';
import { Loading } from '../Common/Loading';

interface InventoryPendingTaskTableProps {
  rows: InventoryTask[];
  filter: InventoryTaskQueryParams;
  tasksPages: number;
  tasksPage: number;
  tasksTake: number;
  selectedRows: SelectedTasks;
  rowsIndex: Array<number>;
  handlerFinishTask: (inventoryTasksIds: Array<string>) => void;
  onChangePage(usersTake: number): void;
  onChangePageSize(pageSize: number): void;
  handleSelectedRowsChange: (rowIndex: number) => void;
  handlerChangeProduct: (
    rowIndex: number,
    indexProduct: number,
    checked: boolean
  ) => void;
  loading: boolean;
}

const InventoryPendingTaskTable = ({
	rows,
	filter,
	tasksPages,
	tasksPage,
	tasksTake,
	selectedRows,
	rowsIndex,
	onChangePage,
	handlerFinishTask,
	onChangePageSize,
	handleSelectedRowsChange,
	handlerChangeProduct,
	loading,
}: InventoryPendingTaskTableProps): JSX.Element => {
	const [expandedRows, setExpandedRows] = useState<number[]>([]);
	const [openActionTables, setOpenActionTables] = useState<
    Record<string, boolean>
  >({});

	const handleRowExpand = useCallback((rowIndex: number) => {
		setExpandedRows((prev) => {
			const isRowExpanded = prev.includes(rowIndex);
			return isRowExpanded
				? prev.filter((row) => row !== rowIndex)
				: [...prev, rowIndex];
		});
	}, []);

	const columnSaleOrderIsVisible = useCallback(
		(type: InventoryTaskAction) => {
			const typeScreen = filter.type !== undefined ? filter.type : defaultFilterContextScreen;

			return !(typeScreen === type);
		},
		[filter.type],
	);

	const handlePrint = useCallback(
		(index: number, logoUrl: string) => {
			const row = rows[index];
			const subgridItems = row.inventoryTasks;

			generatePDF(row, subgridItems, logoUrl);
		},
		[rows],
	);

	const columns: Array<MUIDataTableColumnDef> = useMemo(() => {
		let columnsTable: Array<MUIDataTableColumnDef> = columnTransfer;

		const type = filter.type !== undefined ? filter.type : defaultFilterContextScreen;

		const isStorageOrExpedition = [
			InventoryTaskAction.SUPPLY,
			InventoryTaskAction.DISPATCH,
		].includes(type as InventoryTaskAction);

		if (isStorageOrExpedition) {
			columnsTable = columnStorageAndExpedition({
				filter,
				columnSaleOrderIsVisible,
				handlePrint,
			});
		}

		return columnsTable;
	}, [columnSaleOrderIsVisible, filter, handlePrint]);

	const buildQuantitySecondUniMeasurement = useCallback(
		(product: TaskProduct, quantity: number) => {
			const valueDecimal = new Decimal(quantity);
			const conversionIndex = Number(product.conversionIndex);
			let quantityCalculated = valueDecimal.dividedBy(conversionIndex);

			if (product.conversionType === ConversionType.DIVIDER) {
				quantityCalculated = valueDecimal.times(conversionIndex);
			}

			return String(quantityCalculated);
		},
		[],
	);

	const isRowEnabled = useCallback(
		(dataIndex: number) => {
			const status = [InventoryTaskStatus.PENDING];
			return status.includes(rows[dataIndex].status);
		},
		[rows],
	);

	const isCheckedProductEnabled = useCallback(
		(
			status: InventoryTaskStatus,
			locationDestinyId: string | null,
			locationOriginId: string | null,
		) => {
			if (status !== InventoryTaskStatus.PENDING) {
				return false;
			}

			return Boolean(locationDestinyId && locationOriginId);
		},
		[],
	);

	const isChecked = useCallback(
		(inventoryTask: InventoryTask, task: Task) => {
			const tasksData = selectedRows[inventoryTask.id]?.tasksIds ?? [];

			if (!tasksData.includes(task.id)) {
				return false;
			}

			if (tasksData.includes(task.id)) {
				return true;
			}

			if (inventoryTask.selected && Boolean(task.locationDestinyId)) {
				return true;
			}

			return false;
		},
		[selectedRows],
	);

	const buildLabelColumnQuantityCounted = useMemo(() => {
		let labelStart = '';
		const labelEnd = 'realizada';

		const type = filter.type !== undefined ? filter.type : defaultFilterContextScreen;

		switch (type) {
			case InventoryTaskAction.DISPATCH:
				labelStart = 'Expedição';
				break;
			case InventoryTaskAction.MOVE:
				labelStart = 'Transferência';
				break;
			default:
				labelStart = 'Armazenagem';
		}

		return labelStart.concat(` ${labelEnd}`);
	}, [filter.type]);

	const buildTitleDetailsProducts = useMemo(() => {
		let labelValue = '';

		const type = filter.type !== undefined ? filter.type : defaultFilterContextScreen;

		switch (type) {
			case InventoryTaskAction.DISPATCH:
				labelValue = 'Itens do pedido';
				break;
			case InventoryTaskAction.MOVE:
				labelValue = 'Produto da Transferência';
				break;
			default:
				labelValue = 'Itens da nota';
		}
		return labelValue;
	}, [filter.type]);

	const buildLabelStatusTask = useCallback(
		(status: InventoryTaskStatus) => {
			let statusMap: StatusTaskMap = {} as StatusTaskMap;

			const type = filter.type !== undefined ? filter.type : defaultFilterContextScreen;

			switch (type) {
				case InventoryTaskAction.DISPATCH:
					statusMap = createStatusTaskMap({ operatingLabel: 'Em Expedição' });
					break;
				case InventoryTaskAction.MOVE:
					statusMap = createStatusTaskMap({
						operatingLabel: 'Em Transferência',
					});
					break;
				default:
					statusMap = createStatusTaskMap({ operatingLabel: 'Em Armazenagem' });
			}
			return statusMap[status]?.label || 'Status Desconhecido';
		},
		[filter.type],
	);

	const handleMenuItemClick = useCallback(
		(openActionTable: boolean, indexItemSelected: number, inventoryTask: Task) => {
			const isFinishTask = indexItemSelected === 0;

			if (isFinishTask) {
				const { id } = inventoryTask;
				handlerFinishTask([id]);
			}
		},
		[handlerFinishTask],
	);

	const handleToggle = useCallback(
		(open: boolean, rowIndex: number, taskIndex: number) => {
			setOpenActionTables((prev) => ({
				...prev,
				[`${rowIndex}-${taskIndex}`]: open,
			}));
		},
		[],
	);

	const renderDetailsProducts = useCallback(
		(rowMeta: { dataIndex: number; rowIndex: number }) => (
			<tr>
				<td colSpan={12}>
					<TableContainer component={Paper} sx={{ padding: 2 }}>
						<Typography variant="h6" gutterBottom component="div">
							{buildTitleDetailsProducts}
						</Typography>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Produto</TableCell>
									<TableCell>Status</TableCell>
									<TableCell align="right">Quantidade</TableCell>
									<TableCell>1° UM</TableCell>
									<TableCell align="right">Quantidade</TableCell>
									<TableCell>2° UM</TableCell>
									<TableCell>Localização origem</TableCell>
									<TableCell>Localização destino</TableCell>
									<TableCell>Contagem</TableCell>
									<TableCell>{buildLabelColumnQuantityCounted}</TableCell>
									<TableCell>Ações</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{rows[rowMeta.rowIndex].inventoryTasks.map(
									(inventoryTask, index) => (
										<TableRow key={inventoryTask.id}>
											<TableCell component="th" scope="row" size="small">
												<Box sx={{ display: 'flex', alignItems: 'center' }}>
													<Box pr={2}>
														<Checkbox
															checked={isChecked(
																rows[rowMeta.rowIndex],
																inventoryTask,
															)}
															value={isChecked(
																rows[rowMeta.rowIndex],
																inventoryTask,
															)}
															onChange={(
																event: ChangeEvent<HTMLInputElement>,
																checked: boolean,
															) => handlerChangeProduct(
																rowMeta.rowIndex,
																index,
																checked,
															)}
															inputProps={{ 'aria-label': 'controlled' }}
															disabled={
																!isCheckedProductEnabled(
																	inventoryTask.status,
																	inventoryTask.locationDestinyId,
																	inventoryTask.locationOriginId,
																)
															}
														/>
													</Box>
													<Box>{`${inventoryTask.product.code} - ${inventoryTask.product.description}`}</Box>
												</Box>
											</TableCell>
											<TableCell
												component="th"
												scope="row"
												size="small"
												sx={{ whiteSpace: 'nowrap' }}
											>
												{buildLabelStatusTask(inventoryTask.status)}
											</TableCell>
											<TableCell
												component="th"
												scope="row"
												align="right"
												size="small"
											>
												{formatNumberForLocale(inventoryTask.quantity, 2)}
											</TableCell>
											<TableCell component="th" scope="row" size="small">
												{inventoryTask.product.measures[0]?.description || '-'}
											</TableCell>
											<TableCell
												component="th"
												scope="row"
												align="right"
												size="small"
											>
												{formatNumberForLocale(
													buildQuantitySecondUniMeasurement(
														inventoryTask.product,
														Number(inventoryTask.quantity),
													),
													2,
												) || '-'}
											</TableCell>
											<TableCell component="th" scope="row" size="small">
												{inventoryTask.product.measures[1]?.description || '-'}
											</TableCell>
											<TableCell component="th" scope="row" size="small">
												{inventoryTask.locationOriginBarCode || '-'}
											</TableCell>
											<TableCell component="th" scope="row" size="small">
												{inventoryTask.locationDestinyBarCode || '-'}
											</TableCell>
											<TableCell component="th" scope="row" size="small">
												{inventoryTask.diff !== null
													&& countDiffTask[inventoryTask.diff]?.label
													? countDiffTask[inventoryTask.diff]?.label
													: '-'}
											</TableCell>
											<TableCell component="th" scope="row" size="small">
												{formatNumberForLocale(
													inventoryTask.quantityCounted,
													2,
												) || '-'}
											</TableCell>
											{inventoryTask.status !== InventoryTaskStatus.FINISHED && (
												<TableCell component="th" scope="row" size="small">
													<ButtonGroupDropDown
														options={actionTable}
														showLabelButton={false}
														buttonRightProps={{ icon: <MoreHorizIcon /> }}
														open={openActionTables[`${rowMeta.rowIndex}-${index}`] || false}
														handleMenuItemClick={(indexItemSelected) => handleMenuItemClick(
															openActionTables[
																`${rowMeta.rowIndex}-${index}`
															] || false,
															indexItemSelected,
															inventoryTask,
														)}
														handleToggle={(open) => handleToggle(open, rowMeta.rowIndex, index)}
													/>
												</TableCell>
											)}
										</TableRow>
									),
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</td>
			</tr>
		),
		[
			buildTitleDetailsProducts,
			buildLabelColumnQuantityCounted,
			rows,
			isChecked,
			isCheckedProductEnabled,
			buildLabelStatusTask,
			buildQuantitySecondUniMeasurement,
			openActionTables,
			handlerChangeProduct,
			handleMenuItemClick,
			handleToggle,
		],
	);

	const options: MUIDataTableOptions = {
		count: tasksPages,
		filter: false,
		print: false,
		search: false,
		download: false,
		selectableRowsHeader: false,
		selectableRows: 'multiple',
		isRowSelectable: isRowEnabled,
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		customToolbarSelect: () => {},
		rowsSelected: rowsIndex,
		filterType: 'dropdown',
		responsive: 'simple',
		rowsPerPage: tasksTake,
		expandableRows: true,
		serverSide: true,
		page: tasksPage,
		textLabels: {
			pagination: {
				rowsPerPage: 'Quantidade por página:',
			},
			selectedRows: {
				text: 'Notas(s) selecionada(s)',
			},
			body: {
				noMatch: loading ? (
					<Loading />
				) : (
					'Nenhum registro encontrado.'
				),
			},
		},
		rowsPerPageOptions: [10, 20, 50, 100],
		onChangePage: (currentPage) => onChangePage(currentPage),
		onChangeRowsPerPage: onChangePageSize,
		onRowSelectionChange: (
			currentRowsSelected: Array<{ index: number; dataIndex: number }>,
		) => {
			handleSelectedRowsChange(currentRowsSelected[0].index);
		},
		renderExpandableRow: (
			rowData: string[],
			rowMeta: {
        dataIndex: number;
        rowIndex: number;
      },
		) => renderDetailsProducts(rowMeta),
		expandableRowsHeader: false,
		expandableRowsOnClick: true,
		isRowExpandable: () => true,
		rowsExpanded: expandedRows,
		onRowExpansionChange: (currentRowsExpanded) => {
			if (currentRowsExpanded[0]) {
				handleRowExpand(currentRowsExpanded[0].dataIndex);
			}
		},
	};

	return (
		<MUIDataTable title="" data={rows} columns={columns} options={options} />
	);
};

export default InventoryPendingTaskTable;
