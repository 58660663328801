import React, {
	useCallback,
	useMemo,
	useState,
} from 'react';
import {
	Box,
	Tooltip,
} from '@mui/material';
import {
	GridActionsCellItem,
	GridRenderCellParams,
} from '@mui/x-data-grid';
import {
	Delete,
	Edit,
} from '@mui/icons-material';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import {
	EditProductFormProps,
	ILocation, ILocationProductTableRow,
	ILocationWithPagination,
} from '../../containers/Location/LocationAssets';
import { locationProductsColumns } from '../../constants/locationProductsColumns';
import useConfirmationDialog from '../../hooks/useConfirmationDialog';
import AddProductModal, { AddProductFormProps } from './Modals/AddProductModal';
import { ProductQueryParams } from '../../interfaces/ProductQueryParams';
import { IProductWithoutDetails } from '../../containers/Product/ProductAssets';
import EditProductModal from './Modals/EditProductModal';
import NoDataPage from '../Common/NoDataPage';
import LocationAccordion from './LocationAccordion';

interface LocationTableProps {
    location: ILocationWithPagination;
    loading: boolean;
    products: IProductWithoutDetails[];
    updateLocationProduct: (
        productId: string,
        locationId: string,
        values: EditProductFormProps,
    ) => Promise<void>;
    saveLocationProduct: (
        productId: string,
        locationId: string,
        valuedQuantity: string,
    ) => void;
    getProducts(queryParams: ProductQueryParams): void;
    deleteLocationProduct(productId: string, locationId: string): void;
    printLabel(barCode: string): void;
    handleProductLocationActiveStatus: (
        locationId: string,
        productId: string,
        activeProduct: boolean,
    ) => void;
}

const LocationTable = ({
	location,
	loading,
	products,
	updateLocationProduct,
	saveLocationProduct,
	getProducts,
	deleteLocationProduct,
	printLabel,
	handleProductLocationActiveStatus,
}: LocationTableProps): JSX.Element => {
	const [openEditModal, setOpenEditModal] = useState(false);
	const [openAddModal, setOpenAddModal] = useState(false);
	const [
		selectedLocationProduct,
		setSelectedLocationProduct,
	] = useState<ILocationProductTableRow | null>(null);
	const [selectedLocation, setSelectedLocation] = useState<ILocation | null>(null);

	const { requestConfirm, confirmationDialog } = useConfirmationDialog();

	const handleClose = useCallback((): void => {
		setOpenEditModal(false);
		setSelectedLocationProduct(null);
	}, []);

	const handleCloseAdd = useCallback((): void => {
		setOpenAddModal(false);
	}, []);

	const handleOpenModalAddProduct = useCallback((locationData: ILocation): void => {
		setSelectedLocation(locationData);
		setOpenAddModal(true);
	}, []);

	const handleOpenModalEditProduct = useCallback((
		locationProduct: ILocationProductTableRow,
	): void => {
		setSelectedLocationProduct(locationProduct);
		setOpenEditModal(true);
	}, []);

	const handleAddProduct = useCallback(async (
		values: AddProductFormProps,
		locationData: ILocation,
	) => {
		await saveLocationProduct(values.productId, locationData.id, values.valuedQuantity);
		handleCloseAdd();
	}, [handleCloseAdd, saveLocationProduct]);

	const handleEditProduct = useCallback(async (
		productId: string,
		locationId: string,
		values: EditProductFormProps,
	) => {
		await updateLocationProduct(
			productId,
			locationId,
			values,
		);
		setOpenEditModal(false);
	}, [updateLocationProduct]);

	const handleDeleteProduct = useCallback((row: ILocationProductTableRow): void => {
		deleteLocationProduct(
			row.locationProduct.productId,
			row.locationProduct.locationId,
		);
	}, [deleteLocationProduct]);

	const handleActiveProductLocation = useCallback((row: ILocationProductTableRow): void => {
		handleProductLocationActiveStatus(
			row.locationProduct.locationId,
			row.locationProduct.productId,
			!row.locationProduct.activeProduct,
		);
	}, [handleProductLocationActiveStatus]);

	const columns = useMemo(() => [...locationProductsColumns,
		{
			field: 'actions',
			headerName: 'Ações',
			flex: 1,
			renderCell: (params: GridRenderCellParams) => (
				<>
					<Tooltip title="Editar">
						<GridActionsCellItem
							icon={<Edit />}
							label="Editar"
							color="primary"
							disabled={!params.row.locationProduct.activeProduct}
							onClick={() => handleOpenModalEditProduct(params.row)}
						/>
					</Tooltip>
					<Tooltip title={params.row.locationProduct.activeProduct ? 'Desativar' : 'Ativar'}>
						<GridActionsCellItem
							icon={params.row.locationProduct.activeProduct ? <ToggleOnIcon /> : <ToggleOffIcon />}
							label={params.row.locationProduct.activeProduct ? 'Desativar' : 'Ativar'}
							color={params.row.locationProduct.activeProduct ? 'primary' : 'default'}
							onClick={() => handleActiveProductLocation(params.row)}
						/>
					</Tooltip>
					<Tooltip title="Remover">
						<GridActionsCellItem
							icon={<Delete />}
							label="Excluir"
							color="error"
							onClick={() => requestConfirm({
								description: 'Tem certeza que deseja remover o produto selecionado?',
								callback: () => handleDeleteProduct(params.row),
							})}
						/>
					</Tooltip>
				</>
			),
		},
	], [
		handleActiveProductLocation,
		handleDeleteProduct,
		handleOpenModalEditProduct,
		requestConfirm,
	]);

	if (location.locations.length === 0 && !loading) {
		return (
			<NoDataPage
				title="Nenhuma Localização Dísponivel"
				description="Crie uma localização para começar a visualizar."
			/>
		);
	}

	return (
		<>
			<Box>
				<AddProductModal
					open={openAddModal}
					onClose={handleCloseAdd}
					loading={loading}
					onSubmitAdd={handleAddProduct}
					getProducts={getProducts}
					location={selectedLocation}
					products={products}
				/>

				<EditProductModal
					open={openEditModal}
					onClose={handleClose}
					locationProduct={selectedLocationProduct}
					loading={loading}
					onSubmit={handleEditProduct}
				/>

				{location.locations.map((locationData) => (
					<LocationAccordion
						key={locationData.id}
						locationData={locationData}
						handleOpenModalAddProduct={handleOpenModalAddProduct}
						printLabel={printLabel}
						columns={columns}
						loading={loading}
					/>
				))}
			</Box>
			{confirmationDialog}
		</>
	);
};

export default LocationTable;
