import React, {
	useCallback, useEffect, useMemo, useState,
} from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import Box from '@mui/material/Box';
import { Loading } from '../Common/Loading';
import DrawerFilter from '../Common/DrawerFilter';
import useDataGridManagement from '../../hooks/useDataGridManagement';
import {
	InventoryTaskQueryParams,
	InventoryTaskTransferProductData,
	InventoryTaskTransferProductParams,
	ReleaseTasksParams,
	RemoveTasksParams,
	ReprocessTasksParams,
} from '../../interfaces/InventoryTaskQueryParams';
import { PageHeader } from '../Common/PageHeader/PageHeader';
import InventoryPendingTaskTable from './InventoryPendingTaskTable';
import { PageHeaderButtonProps } from '../../interfaces/PageHeaderInterface';
import {
	ChangeScreenTask,
	InventoryTask,
	Task,
} from '../../containers/Task/InventoryTaskAssets';
import { InventoryTaskStatus } from '../../enums/InventoryTaskStatus';
import InventoryPendingTaskFilter from './InventoryPendingTaskFilter';
import {
	changeScreenData,
	defaultFilterContextScreen,
	defaultInventoryTaskQueryParams,
} from '../../constants/inventoryTask';
import { ButtonGroupDropDown } from '../Common/Button/ButtonGroup';
import TransferProductModal from './Modals/TransferProductModal';
import { ProductQueryParams } from '../../interfaces/ProductQueryParams';
import {
	ILocationProductData,
	IProductWithoutDetails,
} from '../../containers/Product/ProductAssets';
import { InventoryTaskAction } from '../../enums/InventoryTaskAction';
import { BranchParams } from '../../containers/Inventory/InventoryAssets';
import { ILocationProductWithoutProduct, LocationProductWithoutProductQueryParams } from '../../interfaces/LocationProduct';

interface InventoryPendingTaskProps {
  loading: boolean;
  tasks: Array<InventoryTask>;
  tasksPages: number;
  tasksPage: number;
  tasksTake: number;
  products: IProductWithoutDetails[];
  productLocations: ILocationProductData[];
  locationWithoutProducts: ILocationProductWithoutProduct[];
  getTasks: (queryParams: InventoryTaskQueryParams) => void;
  releaseTasks: (data: ReleaseTasksParams) => void;
  reprocessLocationTasks: (data: ReprocessTasksParams) => void;
  finishTask: (data: RemoveTasksParams) => void;
  getProducts: (queryParams: ProductQueryParams) => void;
  getProductLocationsData: (productId: string) => Promise<void>;
  getLocationProductWithoutProduct: (params: LocationProductWithoutProductQueryParams) => void;
  createTransferProductTask: (data: InventoryTaskTransferProductData) => void;
  getCompanies: () => void;
  getBranches: (params: BranchParams) => void;
}

interface TaskGroup {
  tasksIds: Array<string>;
}

export interface SelectedTasks {
  [key: string]: TaskGroup;
}

const InventoryPendingTask = (
	props: InventoryPendingTaskProps,
): JSX.Element => {
	const {
		loading,
		tasks,
		tasksPages,
		tasksPage,
		tasksTake,
		products,
		productLocations,
		locationWithoutProducts,
		getTasks,
		releaseTasks,
		reprocessLocationTasks,
		finishTask,
		getProducts,
		getProductLocationsData,
		getLocationProductWithoutProduct,
		createTransferProductTask,
		getBranches,
		getCompanies,
	} = props;

	const {
		filter, setFilter, onChangePage, onChangePageSize,
	} = useDataGridManagement<InventoryTaskQueryParams>({
		initialFilterValues: { status: InventoryTaskStatus.PENDING, skip: 0 },
		initialPageSize: tasksTake,
		initialPage: tasksPage,
		fetchData: getTasks,
	});

	const [selectedRows, setSelectedRows] = useState<SelectedTasks>(
    {} as SelectedTasks,
	);
	const [rowsIndex, setRowsIndex] = useState<Array<number>>([]);
	const [allSelected, setAllSelected] = useState(false);
	const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
	const [isTransferProductModalOpen, setIsTransferProductModalOpen] = useState(false);
	const [openChangeScreen, setOpenChangeScreen] = useState(false);

	useEffect(() => {
		getCompanies();
	}, [getCompanies]);

	const resetValueScreen = useCallback(() => {
		setAllSelected(false);
		setRowsIndex([]);
		setSelectedRows({} as SelectedTasks);
	}, []);

	const handlerTransferProductModal = useCallback(
		async (open: boolean) => {
			if (open) {
				await getProducts({ skip: 0 });
			}

			setIsTransferProductModalOpen(open);
		},
		[getProducts],
	);

	const getIdsInventoryTasksFiltered = useCallback(
		(
			allInventoryTask: Array<Task>,
			statusProduct: InventoryTaskStatus,
		) => allInventoryTask
			.filter(
				(inventoryTask) => inventoryTask.status === statusProduct
            && Boolean(inventoryTask.locationDestinyId) && Boolean(inventoryTask.locationOriginId),
			)
			.map((taskData) => taskData.id),
		[],
	);

	const getTaskIdsByType = useCallback(
		(
			allInventoryTask: Array<Task>,
			statusProduct: InventoryTaskStatus,
			hasDestiny: boolean,
		) => {
			let tasksIds: string[] = [];

			const type = filter.type !== undefined ? filter.type : defaultFilterContextScreen;

			if (type === InventoryTaskAction.SUPPLY) {
				tasksIds = allInventoryTask
					.filter(
						(inventoryTask) => inventoryTask.status === statusProduct
              && Boolean(inventoryTask.locationDestinyId) === hasDestiny,
					)
					.map((taskData) => taskData.id);
			} else if (type === InventoryTaskAction.DISPATCH) {
				tasksIds = allInventoryTask
					.filter(
						(inventoryTask) => inventoryTask.status === statusProduct
              && Boolean(inventoryTask.locationOriginId) === hasDestiny,
					)
					.map((taskData) => taskData.id);
			}

			return tasksIds;
		},
		[filter.type],
	);

	const handlerReleaseTask = useCallback(() => {
		const allTasksIds: ReleaseTasksParams = {
			tasks: Object.values(selectedRows).flatMap(
				(selectedRow) => selectedRow.tasksIds,
			),
		};

		releaseTasks(allTasksIds);
		setFilter({ ...filter });
		resetValueScreen();
	}, [selectedRows, filter, setFilter, releaseTasks, resetValueScreen]);

	const selectedAllPendingsRows = useCallback(() => {
		const indexs: Array<number> = [];
		const rowsSelected: SelectedTasks = {} as SelectedTasks;
		const selectedAll = !allSelected;

		if (selectedAll) {
			Object.entries(tasks).forEach(
				(value: [string, InventoryTask], index: number) => {
					const [, task] = value;

					if (task.status === InventoryTaskStatus.FINISHED) {
						return;
					}

					indexs.push(index);

					const taskIds = getIdsInventoryTasksFiltered(
						task.inventoryTasks,
						InventoryTaskStatus.PENDING,
					);

					rowsSelected[task.id] = {
						tasksIds: [...taskIds],
					};
				},
			);
		}

		setAllSelected(selectedAll);
		setRowsIndex(indexs);
		setSelectedRows(rowsSelected);
	}, [allSelected, tasks, getIdsInventoryTasksFiltered]);

	const handleSelectedRowsChange = useCallback(
		(rowIndex: number): void => {
			const task = tasks[rowIndex] ?? {};
			const taskId = task.id;

			if (taskId) {
				setRowsIndex((data) => [...data, rowIndex]);
				task.selected = !task.selected;
				const taskIds = getIdsInventoryTasksFiltered(
					task.inventoryTasks,
					InventoryTaskStatus.PENDING,
				);

				setSelectedRows((prevSelectedTasks) => {
					const newSelectedTasks = { ...prevSelectedTasks };

					if (newSelectedTasks[taskId] && task.selected) {
						newSelectedTasks[taskId] = {
							tasksIds: [...taskIds],
						};
					} else if (task.selected) {
						newSelectedTasks[taskId] = {
							tasksIds: [
								...(newSelectedTasks[taskId]?.tasksIds || []),
								...taskIds,
							],
						};
					} else {
						setRowsIndex((indexs) => indexs.filter((index) => index !== rowIndex));
						delete newSelectedTasks[taskId];
					}

					return newSelectedTasks;
				});
			}
		},
		[tasks, getIdsInventoryTasksFiltered],
	);

	const handlerChangeProduct = useCallback(
		(rowIndex: number, indexProduct: number, checked: boolean) => {
			const task = tasks[rowIndex];
			const inventoryTask = task.inventoryTasks[indexProduct];
			const taskId = task.id;
			const inventoryId = inventoryTask.id;

			if (taskId) {
				setSelectedRows((prevSelectedTasks) => {
					const newSelectedTasks = { ...prevSelectedTasks };
					const currentTasksIds = newSelectedTasks[taskId]?.tasksIds ?? [];

					if (checked) {
						newSelectedTasks[taskId] = {
							tasksIds: [...currentTasksIds, inventoryId],
						};

						const inventoryTaskFilter = getIdsInventoryTasksFiltered(
							task.inventoryTasks,
							InventoryTaskStatus.PENDING,
						);

						if (newSelectedTasks[taskId]?.tasksIds.length === inventoryTaskFilter.length) {
							tasks[rowIndex].selected = true;
							setRowsIndex((indexs) => [...indexs, rowIndex]);
						}
					} else {
						newSelectedTasks[taskId] = {
							tasksIds: currentTasksIds.filter((id) => id !== inventoryId),
						};

						setRowsIndex((indexs) => indexs.filter((index) => index !== rowIndex));
						if (newSelectedTasks[taskId]?.tasksIds.length === 0) {
							delete newSelectedTasks[taskId];
						}
					}

					return newSelectedTasks;
				});
			}
		},
		[tasks, getIdsInventoryTasksFiltered],
	);

	const buttonReprocessLocationEnabled = useMemo(() => {
		let enableButton = false;
		const type = filter.type !== undefined ? filter.type : defaultFilterContextScreen;

		if (type === InventoryTaskAction.SUPPLY) {
			enableButton = tasks.some(
				(task) => task.inventoryTasks.some(
					(inventoryTask) => (inventoryTask.locationDestinyId === null
						|| inventoryTask.locationOriginId === null)
				&& inventoryTask.status === InventoryTaskStatus.PENDING,
				),
			);
		} else if (type === InventoryTaskAction.DISPATCH) {
			enableButton = tasks.some(
				(task) => task.inventoryTasks.some(
					(inventoryTask) => (inventoryTask.locationDestinyId === null
						|| inventoryTask.locationOriginId === null)
				&& inventoryTask.status === InventoryTaskStatus.PENDING,
				),
			);
		}
		return enableButton;
	}, [filter.type, tasks]);

	const handlerReprocessLocation = useCallback(() => {
		const tasksIdsWithoutDestiny = tasks
			.filter((task) => task.inventoryTasks.filter((
				inventoryTask,
			) => inventoryTask.status === InventoryTaskStatus.PENDING))
			.flatMap((task) => getTaskIdsByType(
				task.inventoryTasks,
				InventoryTaskStatus.PENDING,
				false,
			));

		reprocessLocationTasks({ tasks: tasksIdsWithoutDestiny });
		resetValueScreen();
		setFilter({ ...filter });
	}, [
		setFilter,
		filter,
		getTaskIdsByType,
		reprocessLocationTasks,
		tasks,
		resetValueScreen,
	]);

	const toggleFilterDrawer = useCallback(() => {
		setFilterDrawerOpen((value) => !value);
	}, []);

	const handlerValueFilter = useCallback(
		(filterData: Partial<InventoryTaskQueryParams>) => {
			setFilter(filterData as InventoryTaskQueryParams);
			toggleFilterDrawer();
		},
		[setFilter, toggleFilterDrawer],
	);

	const onSubmitTransferProduct = useCallback(
		(trasferData: InventoryTaskTransferProductParams) => {
			const productQuantity = Number(trasferData.productQuantity);
			const dataTransferProduct = {
				...omit(trasferData, ['product']),
				productQuantity,
				invoiceValue: productQuantity,
				locationOriginId: trasferData.locationOriginId ?? '',
				locationDestinyId: trasferData.locationDestinyId ?? '',
				productCode: trasferData.productCode ?? '',
			};
			createTransferProductTask({ data: [dataTransferProduct] });
			setFilter({ ...filter });
			setIsTransferProductModalOpen(false);
			getTasks(filter);
		},
		[createTransferProductTask, setFilter, filter, getTasks],
	);

	const filterMemo = useMemo(
		() => (
			<DrawerFilter open={isFilterDrawerOpen} onClose={toggleFilterDrawer}>
				<InventoryPendingTaskFilter
					initialValues={{ ...defaultInventoryTaskQueryParams, ...filter }}
					handlerValueFilter={handlerValueFilter}
					getBranches={getBranches}
					products={products}
					locationWithoutProducts={locationWithoutProducts}
					getProducts={getProducts}
					getLocationProductWithoutProduct={getLocationProductWithoutProduct}
				/>
			</DrawerFilter>
		),
		[
			isFilterDrawerOpen,
			toggleFilterDrawer,
			filter,
			handlerValueFilter,
			getBranches,
			products,
			getProducts,
			locationWithoutProducts,
			getLocationProductWithoutProduct,
		],
	);

	const transferProductModalMemo = useMemo(
		() => (
			<TransferProductModal
				open={isTransferProductModalOpen}
				loading={loading}
				products={products}
				getProducts={getProducts}
				productLocations={productLocations}
				getProductLocationsData={getProductLocationsData}
				onClose={handlerTransferProductModal}
				onSubmit={onSubmitTransferProduct}
			/>
		),
		[
			getProducts,
			getProductLocationsData,
			handlerTransferProductModal,
			isTransferProductModalOpen,
			loading,
			onSubmitTransferProduct,
			productLocations,
			products,
		],
	);

	const handleToggle = (open: boolean): void => {
		setOpenChangeScreen(open);
	};

	const handleMenuItemClick = useCallback(
		(index: number, data: ChangeScreenTask): void => {
			resetValueScreen();

			const filterData = pick(filter, ['take']);
			setFilter({
				take: filterData.take,
				skip: 0,
				type: data.value,
				indexContext: index,
				status: InventoryTaskStatus.PENDING,
			});
			localStorage.setItem('inventoryType', String(data.value));
			localStorage.setItem('indexContext', String(index));

			handleToggle(false);
		},
		[filter, setFilter, resetValueScreen],
	);

	const handlerFinishTask = useCallback((inventoryTasksIds: Array<string>) => {
		if (inventoryTasksIds?.length) {
			finishTask({ tasks: inventoryTasksIds });
			resetValueScreen();
			setFilter({ ...filter });
		}
	}, [filter, finishTask, resetValueScreen, setFilter]);

	const type = filter.type !== undefined ? filter.type : defaultFilterContextScreen;

	const headerButtons: PageHeaderButtonProps[] = useMemo(
		() => [
			{
				text: 'Filtros',
				variant: 'contained',
				onClick: toggleFilterDrawer,
				startIcon: <FilterListIcon />,
			},
			{
				isCustomComponent: true,
				component: (
					<ButtonGroupDropDown
						options={changeScreenData}
						selectedIndex={Number(localStorage.getItem('indexContext')) || 0}
						open={openChangeScreen}
						handleMenuItemClick={handleMenuItemClick}
						handleToggle={handleToggle}
					/>
				),
				text: 'Alterar visualização',
			},
			{
				show: type === InventoryTaskAction.MOVE,
				text: 'Transferir',
				variant: 'contained',
				onClick: () => handlerTransferProductModal(true),
			},
			{
				show: Object.keys(selectedRows).length > 0,
				text: 'Liberar tarefas',
				variant: 'contained',
				onClick: handlerReleaseTask,
			},
			{
				show: tasks.some((
					task,
				) => task.inventoryTasks.some((subTask) => subTask.status === InventoryTaskStatus.PENDING)),
				text: !allSelected ? 'Marcar Pendentes' : 'Desmarcar Pendentes',
				variant: 'contained',
				onClick: selectedAllPendingsRows,
				startIcon: !allSelected ? (
					<CheckBoxOutlineBlankIcon />
				) : (
					<CheckBoxIcon />
				),
			},
			{
				show: buttonReprocessLocationEnabled,
				text: 'Reprocessar Localização',
				variant: 'contained',
				color: 'warning',
				onClick: handlerReprocessLocation,
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			toggleFilterDrawer,
			openChangeScreen,
			handleMenuItemClick,
			filter.type,
			selectedRows,
			handlerReleaseTask,
			tasks,
			allSelected,
			selectedAllPendingsRows,
			buttonReprocessLocationEnabled,
			handlerReprocessLocation,
			handlerTransferProductModal,
		],
	);

	return (
		<Box className="content">
			<PageHeader
				title="Gestão de tarefas"
				buttons={headerButtons}
			/>
			{loading && !isTransferProductModalOpen && <Loading />}
			{filterMemo}
			{transferProductModalMemo}
			<InventoryPendingTaskTable
				rows={tasks}
				filter={filter}
				loading={loading}
				tasksPages={tasksPages}
				tasksPage={tasksPage}
				tasksTake={tasksTake}
				selectedRows={selectedRows}
				rowsIndex={rowsIndex}
				handlerFinishTask={handlerFinishTask}
				onChangePageSize={onChangePageSize}
				onChangePage={onChangePage}
				handleSelectedRowsChange={handleSelectedRowsChange}
				handlerChangeProduct={handlerChangeProduct}
			/>
		</Box>
	);
};

export default InventoryPendingTask;
