import React, {
	SyntheticEvent, useCallback, useEffect, useState,
} from 'react';
import {
	TableBody,
	TableRow,
	TableHead,
	Paper,
	Table,
	TableContainer,
	TableCell,
	Box,
	Grid,
	Typography,
	Button,
	DialogContent,
	Dialog,
	DialogTitle,
	DialogActions,
	AutocompleteInputChangeReason,
	Tooltip,
	InputAdornment,
} from '@mui/material';
import {
	FieldArray, Form, FormikContext, useFormik,
} from 'formik';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { AddBox, Edit } from '@mui/icons-material';
import { PageHeader } from '../Common/PageHeader/PageHeader';
import {
	ICreateTask, ICreateTaskFromInvoice, ILinkProductBusinessPartner, ProductsInvoiceQueryParams,
} from '../../interfaces/FiscalDocumentParams';
import { IInvoiceHeader, IInvoiceItem } from '../../containers/FiscalDocumentEntry/FiscalDocumentEntryAssets';
import Autocomplete from '../Common/Form/Autocomplete';
import Input from '../Common/Form/Input';
import { currencyBRLMask } from '../../helpers/intl';
import { PageHeaderButtonProps } from '../../interfaces/PageHeaderInterface';
import { Loading } from '../Common/Loading';
import { applyCnpjMask } from '../../helpers/masks';
import { ProductQueryParams } from '../../interfaces/ProductQueryParams';
import { IProductWithoutDetails } from '../../containers/Product/ProductAssets';
import { getFilteredProducts } from '../../helpers/productSelection';
import { InventoryTaskAction } from '../../enums/InventoryTaskAction';
import { ILocation } from '../../containers/Location/LocationAssets';
import { LocationProductQueryParams } from '../../interfaces/LocationProduct';
import usePermission from '../../hooks/usePermission';
import { formatNumberForLocale } from '../../helpers/NumberUtils';

interface FiscalDocumentEntryEditProps {
	loading: boolean;
	invoiceItems: IInvoiceItem[];
	invoice?: IInvoiceHeader;
	getProductsInvoice(invoiceId: string, params: ProductsInvoiceQueryParams): void;
	getProductsWithoutDetails(queryParams: ProductQueryParams): void;
	products: IProductWithoutDetails[];
	linkProductBusinessPartner(data: ILinkProductBusinessPartner[]): void;
	createTasksInvoice(action: InventoryTaskAction, data: ICreateTask): void;
	locations: ILocation[];
	getLocations: (queryParams?: LocationProductQueryParams) => void;
	createTaskFromInvoice: (
		inventoryTaskInvoiceId: string,
		data: ICreateTaskFromInvoice,
	) => void;
	handleEdit: (businessPartnerId: string) => void;
}

const FiscalDocumentEntryEdit = ({
	loading,
	invoiceItems,
	getProductsInvoice,
	invoice,
	getProductsWithoutDetails,
	products,
	linkProductBusinessPartner,
	createTasksInvoice,
	locations,
	getLocations,
	createTaskFromInvoice,
	handleEdit,
}: FiscalDocumentEntryEditProps): JSX.Element => {
	const [postData, setPostData] = useState<ILinkProductBusinessPartner[]>([]);
	const [selectedProducts, setSelectedProducts] = useState<IProductWithoutDetails[]>([]);
	const [openLocationModal, setOpenLocationModal] = useState(false);
	const permissionBP = usePermission('BUSINESS_PARTNER');
	const { id } = useParams();

	const formik = useFormik({
		initialValues: {
			emissionDate: '',
			nrInvoice: '',
			emit: '',
			taxId: '',
			value: '',
			locationId: '',
			products: {} as Record<string, string>,
		},
		onSubmit: (values) => {
			if (id) {
				setOpenLocationModal(false);
				createTasksInvoice(InventoryTaskAction.SUPPLY, {
					invoiceId: id,
					locationId: values.locationId,
				});
			}
		},
	});

	useEffect(() => {
		if (id) {
			getProductsInvoice(id, { skip: 0 });
			getProductsWithoutDetails({ skip: 0 });
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.setFieldValue, getProductsInvoice, id]);

	useEffect(() => {
		if (invoice?.inventoryTaskInvoiceId === null && locations.length === 0) {
			getLocations({ skip: 0, locationValue: 'Z01' });
		}
	}, [getLocations, invoice?.inventoryTaskInvoiceId, locations.length]);

	useEffect(() => {
		if (invoice?.emissionDate) {
			formik.setFieldValue('emissionDate', format(new Date(invoice?.emissionDate), 'dd/MM/yyyy'));
		}
		formik.setFieldValue('nrInvoice', invoice?.nrInvoice || '');
		formik.setFieldValue('value', currencyBRLMask(invoice?.value || 0));
		formik.setFieldValue('emit', invoice?.businessPartners[0]?.name || '');
		formik.setFieldValue('taxId', applyCnpjMask(invoice?.businessPartners[0]?.taxId || ''));

		invoiceItems.forEach((item) => {
			if (item?.product?.id) {
				formik.setFieldValue(`products.${item.productBusinessPartnerId}`, item.product.id);

				setSelectedProducts((prev) => {
					const exists = prev.some((p) => p.id === item?.product?.id);
					if (!exists && item.product) {
						return [...prev, item.product];
					}
					return prev;
				});

				setPostData((prev) => {
					const exists = prev.some((p) => p.productBusinessPartnerId
					=== item.productBusinessPartnerId);
					if (!exists && item?.product?.id) {
						return [...prev, {
							productBusinessPartnerId: item.productBusinessPartnerId,
							productId: item?.product?.id,
						}];
					}
					return prev;
				});
			}
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [invoice?.businessPartners, invoice?.emissionDate, invoice?.nrInvoice, invoice?.value]);

	const handleChangeProducts = useCallback((
		__event,
		newValue,
		reason,
		productBusinessPartnerId: string,
	) => {
		if (reason === 'selectOption') {
			setSelectedProducts((prev) => {
				const exists = prev.some((p) => p.id === newValue);
				if (!exists) {
					const productToAdd = products.find((p) => p.id === newValue);
					if (productToAdd) {
						return [...prev, productToAdd];
					}
				}
				return prev;
			});

			linkProductBusinessPartner(
				[{
					productBusinessPartnerId,
					productId: newValue,
				}],
			);

			setPostData((prevData) => {
				const existingItemIndex = prevData.findIndex(
					(item) => item.productBusinessPartnerId === productBusinessPartnerId,
				);

				if (existingItemIndex >= 0) {
					const newData = [...prevData];
					newData[existingItemIndex] = {
						...newData[existingItemIndex],
						productId: newValue,
					};
					return newData;
				}
				return [...prevData, {
					productBusinessPartnerId,
					productId: newValue,
				}];
			});

			formik.setFieldValue(`products.${productBusinessPartnerId}`, newValue);
		} else if (reason === 'input') {
			getProductsWithoutDetails({ description: newValue, skip: 0 });
		} else if (reason === 'removeOption' || reason === 'clear') {
			setSelectedProducts((
				prev,
			) => prev.filter((
				p,
			) => !postData.find((
				item,
			) => item.productBusinessPartnerId === productBusinessPartnerId && item.productId === p.id)));

			setPostData((
				prevData,
			) => prevData.filter((item) => item.productBusinessPartnerId !== productBusinessPartnerId));

			const newProducts = { ...formik.values.products };
			delete newProducts[productBusinessPartnerId];
			formik.setValues({ ...formik.values, products: newProducts });

			linkProductBusinessPartner(
				[{
					productBusinessPartnerId,
					productId: null,
				}],
			);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getProductsWithoutDetails, formik.setFieldValue, products, postData]);

	const headerButtonsProps: PageHeaderButtonProps[] = [
		{
			variant: 'contained',
			color: 'primary',
			fullWidth: true,
			component: Button,
			onClick: () => setOpenLocationModal(true),
			disabled: Boolean(invoice?.inventoryTaskInvoiceId || invoice?.statusLink === false)
				|| Object.keys(formik.values.products).length !== invoiceItems.length,
			text: 'Criar Tarefas',
		},
	];

	if (loading) {
		return <Loading />;
	}

	return (
		<FormikContext.Provider value={formik}>
			<Form>
				<Box className="content">
					<PageHeader title="Integração de Produtos" buttons={headerButtonsProps} />
					<Box component={Paper} p={2} mb={2}>
						<Typography variant="h6" color="primary" mb={2}>Dados da Nota</Typography>
						<Grid container spacing={2}>
							<Grid item xs={2}>
								<Input.InputField
									id="emissionDate"
									name="emissionDate"
									label="Data de Emissão"
									InputProps={{ readOnly: true }}
									fullWidth
								/>
							</Grid>
							<Grid item xs={4}>
								<Tooltip
									title={!invoice?.businessPartners[0]?.approved
										&& permissionBP?.isAdmin ? 'Cadastro Incompleto' : ''}
								>
									<Input.InputField
										id="emit"
										name="emit"
										label="Emitente"
										InputProps={{
											readOnly: true,
											endAdornment: (
												(!invoice?.businessPartners[0]?.approved) && permissionBP?.isAdmin && (
													<InputAdornment position="end">
														<Button onClick={() => {
															if (invoice?.businessPartners[0]?.id) {
																handleEdit(invoice?.businessPartners[0]?.id);
															}
														}}
														>
															<Edit />
														</Button>
													</InputAdornment>
												)
											),
										}}
										sx={!invoice?.businessPartners[0]?.approved
											&& permissionBP?.isAdmin ? {
												backgroundColor: '#FCD7D7',
											} : undefined}
										fullWidth
									/>
								</Tooltip>
							</Grid>
							<Grid item xs={2}>
								<Input.InputField
									id="nrInvoice"
									name="nrInvoice"
									label="Nº da Nota/Série"
									InputProps={{ readOnly: true }}
									fullWidth
								/>
							</Grid>
							<Grid item xs={2}>
								<Input.InputField
									id="taxId"
									name="taxId"
									label="CNPJ/CPF do Emitente"
									InputProps={{ readOnly: true }}
									fullWidth
								/>
							</Grid>
							<Grid item xs={2}>
								<Input.InputField
									id="value"
									name="value"
									label="Valor Total"
									InputProps={{ readOnly: true }}
									fullWidth
									textAlign="right"
								/>
							</Grid>
						</Grid>
					</Box>
					<Box component={Paper} p={2}>
						<Typography variant="h6" color="primary" mb={2}>Produtos</Typography>
						<TableContainer>
							<Table sx={{ mb: 4 }}>
								<TableHead>
									<TableRow>
										<TableCell sx={{ flex: 1 }}>Nº Item</TableCell>
										<TableCell sx={{ flex: 2 }}>Produto Interno</TableCell>
										<TableCell sx={{ flex: 1 }}>Produto Documento Fiscal</TableCell>
										<TableCell sx={{ flex: 1 }}>1ª UM Doc. Fiscal</TableCell>
										<TableCell sx={{ flex: 1 }}>2ª UM Doc. Fiscal</TableCell>
										<TableCell sx={{ flex: 1 }}>Quantidade Doc. Fiscal</TableCell>
										<TableCell sx={{ flex: 1, textAlign: 'right' }}>Valor Unitário Doc. Fiscal</TableCell>
										<TableCell sx={{ flex: 1, textAlign: 'right' }}>Valor Total Doc. Fiscal</TableCell>
										<TableCell>Ações</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<FieldArray
										name="productBusinessPartners"
										validateOnChange={false}
										render={() => (
											<>
												{invoiceItems.map((invoiceItem) => (
													<TableRow key={invoiceItem.nrItem}>
														<TableCell>{invoiceItem.nrItem}</TableCell>
														<TableCell width={400}>
															<Autocomplete
																label="Produtos"
																name={`products.${invoiceItem.productBusinessPartnerId}`}
																valueKey="id"
																valueLabel="code"
																labelKey="description"
																options={getFilteredProducts({
																	selectedProducts,
																	availableProducts: products,
																	postData,
																	currentProductBusinessPartnerId:
																	invoiceItem.productBusinessPartnerId,
																})}
																loading={loading}
																onInputChange={(event, newValue, reason) => {
																	if (newValue.length > 2) {
																		handleChangeProducts(
																			event,
																			newValue,
																			reason,
																			invoiceItem.productBusinessPartnerId,
																		);
																	}
																}}
																onChange={(event, newValue, reason) => handleChangeProducts(
																	event,
																	newValue,
																	reason,
																	invoiceItem.productBusinessPartnerId,
																)}
																fullWidth
																readOnly={invoiceItem.product !== null}
															/>
														</TableCell>
														<TableCell>{`${invoiceItem.code} - ${invoiceItem.description}`}</TableCell>
														<TableCell>{invoiceItem.primaryMeasure || '-'}</TableCell>
														<TableCell>{invoiceItem.secondaryMeasure || '-'}</TableCell>
														<TableCell>{formatNumberForLocale(invoiceItem.quantity)}</TableCell>
														<TableCell sx={{ textAlign: 'right' }}>{currencyBRLMask(invoiceItem.price)}</TableCell>
														<TableCell sx={{ textAlign: 'right' }}>{currencyBRLMask(invoiceItem.total)}</TableCell>
														<TableCell>
															{!invoiceItem.hasTask && invoice?.inventoryTaskInvoiceId
																? (
																	<Tooltip title="Recriar Tarefa">
																		<GridActionsCellItem
																			icon={<AddBox />}
																			label="Recriar Tarefa"
																			className="textPrimary"
																			color="primary"
																			onClick={() => {
																				if (invoice?.inventoryTaskInvoiceId) {
																					createTaskFromInvoice(invoice?.inventoryTaskInvoiceId, {
																						productId: invoiceItem.product.id,
																						quantity: invoiceItem.quantity,
																					});
																				}
																			}}
																		/>
																	</Tooltip>
																)
																: '-'}
														</TableCell>
													</TableRow>
												))}
											</>
										)}
									/>
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				</Box>
			</Form>
			<Dialog
				open={openLocationModal}
				maxWidth="sm"
				fullWidth
			>
				<FormikContext.Provider value={formik}>
					<Form>
						<DialogTitle>Informe a localização de origem</DialogTitle>
						<DialogContent>
							<Grid item>
								<Autocomplete
									label="Localização de origem"
									name="locationId"
									valueKey="id"
									valueLabel="barCode"
									formatBarCode
									labelKey="name"
									options={locations}
									loading={loading}
									sx={{ mt: 2 }}
									fullWidth
								/>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => {
								setOpenLocationModal(false);
								formik.setFieldValue('locationId', '');
							}}
							>
								Cancelar
							</Button>
							<Button
								type="submit"
								variant="contained"
								disabled={!formik.values.locationId}
							>
								Confirmar
							</Button>
						</DialogActions>
					</Form>
				</FormikContext.Provider>
			</Dialog>
		</FormikContext.Provider>
	);
};

FiscalDocumentEntryEdit.defaultProps = {
	invoice: undefined,
};

export default FiscalDocumentEntryEdit;
