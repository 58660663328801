import { BusinessPartnerType } from '../../enums/BusinessPartnerType';
import { IProductWithoutDetails } from '../Product/ProductAssets';

export enum StatusInvoice {
    PENDING,
    IN_PROGRESS,
    COMPLETED
}

export enum InvoiceType {
    IN,
    OUT,
}

export interface IFiscalDocument {
    id: string;
    nrInvoice: string;
    serie: string;
    businessPartnerName: string;
    value: number;
    emissionDate: Date | string;
    invoiceType: InvoiceType;
    status: StatusInvoice;
}

export interface IFiscalDocumentEntriesResponse {
    count: number;
    data: IFiscalDocument[];
}

export interface I {
    count: number;
    data: IFiscalDocument[];
}

export interface IInvoiceItem {
	id: string;
	nrItem: number;
	quantity: number;
	price: number;
	total: number;
	code: string;
	description: string;
    product: IProductWithoutDetails
    productBusinessPartnerId: string;
    primaryMeasure?: string;
    secondaryMeasure?: string;
    hasTask: boolean;
}

export interface InvoiceBusinessPartner {
    id: string;
    name: string;
    marketName: string;
    taxId: string;
    type: BusinessPartnerType | null;
    approved: boolean;
}

export interface IInvoiceHeader {
    nrInvoice: string;
    serie: string;
    value: number;
    emissionDate: Date | string;
    invoiceType: InvoiceType;
    businessPartners: InvoiceBusinessPartner[];
    statusLink: boolean;
    inventoryTaskInvoiceId: string;
}

export interface IProductsInvoiceResponse {
    itemCount: number;
    items: IInvoiceItem[];
    invoice: IInvoiceHeader;
}

export interface IFileStatus {
    message: string;
    fileName: string;
    status: 'success' | 'error'
}
