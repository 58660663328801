import React, {
	Dispatch, Reducer, useMemo, useReducer,
} from 'react';
import {
	useSnackbar, SnackbarKey, OptionsObject, SnackbarMessage,
} from 'notistack';
import { AxiosError } from 'axios';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { IFileStatus, IFiscalDocument } from './FiscalDocumentEntryAssets';
import FiscalDocumentEntryPresentational from '../../components/FiscalDocumentEntry/FiscalDocumentEntry';
import { uploadXml, deleteInvoice, getFiscalDocuments } from '../../services/invoice';
import { FiscalDocumentQueryParams } from '../../interfaces/FiscalDocumentParams';
import { ILocation } from '../Location/LocationAssets';

enum ActionType {
	LOADING,
	FISCAL_DOCUMENTS,
	LOCATION,
	LOADING_UPLOAD,
	FILE_STATUS
}

interface IState {
	loading: boolean;
	loadingUpload:boolean;
	fiscalDocuments: IFiscalDocument[];
	fiscalDocumentTake: number;
	fiscalDocumentPage: number;
	fiscalDocumentPages: number;
	locations: ILocation[];
	filesStatus: IFileStatus[];
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.FILE_STATUS; payload: { filesStatus: IFileStatus[] } }
	| { type: ActionType.LOADING_UPLOAD; payload: { loadingUpload: boolean } }
	| { type: ActionType.LOCATION; payload: { locations: ILocation[] } }
	| { type: ActionType.FISCAL_DOCUMENTS; payload: {
		fiscalDocuments: IFiscalDocument[];
		fiscalDocumentTake: number;
		fiscalDocumentPage: number;
		fiscalDocumentPages: number;
	} };

interface IFiscalDocumentActions {
	setLoading(loading: boolean): void;
	setLoadingUpload(loadingUpload: boolean): void;
	getFiscalDocuments(params: FiscalDocumentQueryParams): void;
	handleDeleteInvoice(id: string): void;
	handleEdit(id: string): void;
	uploadXml(data: FormData): void;
	clearFiles(): void;
}

const initialState: IState = {
	loading: false,
	loadingUpload: false,
	fiscalDocuments: [],
	locations: [],
	fiscalDocumentTake: 10,
	fiscalDocumentPage: 0,
	fiscalDocumentPages: 0,
	filesStatus: [],
};

let lastQueryParams: FiscalDocumentQueryParams;

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };

		case ActionType.LOADING_UPLOAD:
			return { ...state, loadingUpload: action.payload.loadingUpload };

		case ActionType.FILE_STATUS:
			return { ...state, filesStatus: action.payload.filesStatus };

		case ActionType.FISCAL_DOCUMENTS:
			return {
				...state,
				fiscalDocuments: action.payload.fiscalDocuments,
				fiscalDocumentPages: action.payload.fiscalDocumentPages,
				fiscalDocumentPage: action.payload.fiscalDocumentPage,
				fiscalDocumentTake: action.payload.fiscalDocumentTake,
			};
		default:
			throw new Error();
	}
};

const FiscalDocumentsActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (
		message: SnackbarMessage,
		options?: OptionsObject | undefined
	) => SnackbarKey,
	navigate: NavigateFunction,
): IFiscalDocumentActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		setLoadingUpload(loadingUpload: boolean) {
			dispatch({ type: ActionType.LOADING_UPLOAD, payload: { loadingUpload } });
		},
		getFiscalDocuments(queryParams: FiscalDocumentQueryParams) {
			actions.setLoading(true);
			const take = queryParams.take ?? 10;
			const params = { ...queryParams, skip: queryParams.skip * take };
			getFiscalDocuments(params)
				.then((response) => {
					dispatch({
						type: ActionType.FISCAL_DOCUMENTS,
						payload: {
							fiscalDocuments: response.data.data,
							fiscalDocumentPages: response.data.count,
							fiscalDocumentPage: queryParams.skip,
							fiscalDocumentTake: take,
						},
					});
					lastQueryParams = params;
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message
			|| 'Algum erro ocorreu ao obter as empresas. Tente novamente ou contate um administrador.',
						{ variant: 'error' },
					);
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		handleDeleteInvoice(id: string) {
			actions.setLoading(true);
			deleteInvoice(id)
				.then((response) => {
					enqueueSnackbar(response?.data.message || 'Documento fiscal excluído com sucesso!', {
						variant: 'success',
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
				})
				.finally(() => {
					actions.setLoading(false);
					actions.getFiscalDocuments({ skip: 0 });
				});
		},
		handleEdit(id: string) {
			navigate(`/edit/${id}`);
		},
		uploadXml(data: FormData) {
			actions.setLoadingUpload(true);
			uploadXml(data)
				.then((response) => {
					dispatch({
						type: ActionType.FILE_STATUS,
						payload: {
							filesStatus: response.data,
						},
					});
					actions.getFiscalDocuments({
						skip: 0,
						take: lastQueryParams?.take ?? 10,
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message
						|| 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
				}).finally(() => {
					actions.setLoadingUpload(false);
				});
		},
		clearFiles() {
			dispatch({
				type: ActionType.FILE_STATUS,
				payload: {
					filesStatus: [],
				},
			});
		},
	};

	return actions;
};

const FiscalDocumentEntryContainer = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const actions = useMemo(
		() => FiscalDocumentsActions(dispatch, enqueueSnackbar, navigate),
		[enqueueSnackbar, navigate],
	);
	return (
		/* eslint-disable react/jsx-props-no-spreading */
		<FiscalDocumentEntryPresentational {...state} {...actions} />
	);
};

export default FiscalDocumentEntryContainer;
