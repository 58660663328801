import React, { useCallback, useEffect, useState } from 'react';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from '@mui/material';
import {
	Form,
	FormikContext,
	useFormik,
} from 'formik';
import Autocomplete from '../../Common/Form/Autocomplete';
import { ICompanyWithoutDetails } from '../../../containers/Company/CompanyAssets';
import { IBranch } from '../../../containers/Branch/BranchAssets';
import { BranchParams, InventoryType, ParamsStartInventory } from '../../../containers/Inventory/InventoryAssets';
import { BRANCH_ID_KEY, COMPANY_ID_KEY } from '../../../services/auth';

interface StartInventoryModalProps {
    open: boolean;
    onClose: () => void;
    getBranches: (params: BranchParams) => void;
	branches: IBranch[];
	loading: boolean;
    handleConfirmStartInventory: (params: ParamsStartInventory) => void;
	companies: ICompanyWithoutDetails[];
}

const initialValues = {
	companyId: null,
	branchId: null,
	type: null,
};

const types = [
	{ label: 'Inventário Geral', id: InventoryType.GERAL },
	{ label: 'Parcial/Cíclico', id: InventoryType.PARTIAL },
];

const StartInventoryModal = ({
	open,
	onClose,
	branches,
	getBranches,
	loading,
	companies,
	handleConfirmStartInventory,
}: StartInventoryModalProps): JSX.Element => {
	const companyId = localStorage.getItem(COMPANY_ID_KEY);
	const branchId = localStorage.getItem(BRANCH_ID_KEY);
	const [showConfirmation, setShowConfirmation] = useState(false);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			...initialValues,
			companyId: companyId || '',
			branchId: branchId || '',
		},
		onSubmit: handleConfirmStartInventory,
	});

	const handleFirstConfirm = useCallback((e: React.FormEvent): void => {
		e.preventDefault();
		setShowConfirmation(true);
	}, []);

	const handleFinalConfirm = useCallback((): void => {
		formik.handleSubmit();
		onClose();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.handleSubmit, onClose]);

	const handleCancel = useCallback((): void => {
		if (showConfirmation) {
			setShowConfirmation(false);
		} else {
			onClose();
		}
	}, [showConfirmation, onClose]);

	const company = companies.find((c) => c.id === formik.values.companyId);
	const branch = branches.find((b) => b.id === formik.values.branchId);
	const type = types.find((t) => t.id === formik.values.type);

	useEffect(() => {
		if (!open) {
			setShowConfirmation(false);
		}
	}, [open]);

	useEffect(() => {
		if (formik.values.companyId) {
			getBranches({
				companyId: formik.values.companyId,
			});
		}
	}, [formik.values.companyId, getBranches]);

	return (
		<FormikContext.Provider value={formik}>
			<Dialog open={open} fullWidth>
				<Form onSubmit={handleFirstConfirm}>
					<DialogTitle>
						{showConfirmation ? 'Tem certeza que deseja iniciar este inventário?' : 'Iniciar inventário'}
					</DialogTitle>
					<DialogContent>
						{!showConfirmation ? (
							<>
								<Autocomplete
									label="Empresa"
									options={companies}
									name="companyId"
									labelKey="name"
									valueKey="id"
									valueLabel="code"
									sx={{ my: 2 }}
									closeOnSelect
									readOnly
									required
								/>
								<Autocomplete
									label="Filial"
									options={branches}
									name="branchId"
									labelKey="name"
									valueKey="id"
									valueLabel="code"
									sx={{ my: 2 }}
									disabled={!formik.values.companyId}
									loading={loading}
									closeOnSelect
									readOnly
									required
								/>
								<Autocomplete
									label="Tipo de Inventário"
									options={types}
									name="type"
									labelKey="label"
									valueKey="id"
									valueLabel="id"
									value={formik.values.type !== null ? formik.values.type : ''}
									disabled={!formik.values.branchId || !formik.values.companyId}
									closeOnSelect
									required
								/>
							</>
						) : (
							<Box>
								<Typography>
									Empresa:
									{' '}
									{company?.name}
								</Typography>
								<Typography>
									Filial:
									{' '}
									{branch?.name}
								</Typography>
								<Typography>
									Tipo de Inventário:
									{' '}
									{type?.label}
								</Typography>
							</Box>
						)}
					</DialogContent>
					<DialogActions sx={{ gap: 1 }}>
						<Button onClick={handleCancel}>Cancelar</Button>
						{!showConfirmation ? (
							<Button
								type="submit"
								variant="contained"
								disabled={
									formik.values.type === null
									|| formik.values.branchId === null
									|| formik.values.companyId === null
									|| loading
								}
							>
								Confirmar
							</Button>
						) : (
							<Button
								onClick={handleFinalConfirm}
								variant="contained"
								color="warning"
							>
								Iniciar Inventário
							</Button>
						)}
					</DialogActions>
				</Form>
			</Dialog>
		</FormikContext.Provider>
	);
};

export default StartInventoryModal;
