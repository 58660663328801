import React, {
	SyntheticEvent,
	useEffect,
	useState,
} from 'react';
import {
	AutocompleteInputChangeReason,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import {
	Form,
	FormikContext,
	useFormik,
} from 'formik';
import {
	ILocationProductWithoutDetails,
} from '../../../containers/Location/LocationAssets';
import Autocomplete from '../../Common/Form/Autocomplete';
import { User } from '../../../containers/User/UserAssets';

export interface CreateInventoryTaskFormProps {
    locationId: string;
    productId: string;
	operatorId?: string;
}

interface CreateInventoryTaskModalProps {
    open: boolean;
    onClose: () => void;
    handleConfirmCreateInventoryTask: (params: CreateInventoryTaskFormProps) => void;
    loading: boolean;
    locations: ILocationProductWithoutDetails[];
	startCount: boolean;
	operators: User[];
	getUsers: () => void;
	countNumber: number;
	getLocationProductWithoutDetails: () => void;
}

const initialValues = {
	locationId: '',
	productId: '',
	operatorId: '',
};

const CreateInventoryTaskModal = ({
	open,
	loading,
	locations,
	handleConfirmCreateInventoryTask,
	onClose,
	startCount,
	operators,
	getUsers,
	countNumber,
	getLocationProductWithoutDetails,
}: CreateInventoryTaskModalProps): JSX.Element => {
	const [
		locationSelected,
		setLocationSelected,
	] = useState<ILocationProductWithoutDetails | null>(null);

	const formik = useFormik({
		initialValues,
		onSubmit: () => {
			handleConfirmCreateInventoryTask({
				locationId: formik.values.locationId,
				productId: formik.values.productId,
				...(formik.values.operatorId && { operatorId: formik.values.operatorId }),
			});

			formik.resetForm();
		},
	});

	useEffect(() => {
		if (open) {
			getLocationProductWithoutDetails();
			if ((startCount && countNumber > 1)
				|| (startCount && countNumber !== 1)) {
				getUsers();
			}
		}
	}, [
		countNumber,
		getLocationProductWithoutDetails,
		getUsers,
		open,
		startCount,
	]);

	useEffect(() => {
		const selected = locations.find((location) => location.id === formik.values.locationId);
		setLocationSelected(selected ?? null);
	}, [formik.values.locationId, locations]);

	return (
		<FormikContext.Provider value={formik}>
			<Dialog open={open} fullWidth>
				<Form>
					<DialogTitle>Criar tarefa do inventário</DialogTitle>
					<DialogContent>
						<Autocomplete
							label="Localização"
							options={locations}
							name="locationId"
							labelKey="name"
							valueKey="id"
							valueLabel="barCode"
							sx={{ my: 2 }}
							loading={loading}
							closeOnSelect
							formatBarCode
							required
						/>
						<Autocomplete
							label="Produto"
							options={locationSelected?.products || []}
							name="productId"
							labelKey="description"
							valueKey="id"
							valueLabel="code"
							sx={{ my: 2 }}
							loading={loading}
							disabled={!formik.values.locationId}
							closeOnSelect
							required
						/>
						{((startCount && countNumber > 1) || (startCount && countNumber !== 1)) && (
							<Autocomplete
								label="Operador"
								options={operators}
								name="operatorId"
								labelKey="login"
								valueKey="id"
								valueLabel="name"
								sx={{ mb: 2 }}
								closeOnSelect
								loading={loading}
								disabled={!formik.values.locationId}
								required={startCount}
							/>
						)}
					</DialogContent>
					<DialogActions sx={{ gap: 1 }}>
						<Button onClick={() => {
							formik.resetForm();
							onClose();
						}}
						>
							Cancelar
						</Button>
						<Button
							type="submit"
							variant="contained"
						>
							Confirmar
						</Button>
					</DialogActions>
				</Form>
			</Dialog>
		</FormikContext.Provider>
	);
};

export default CreateInventoryTaskModal;
