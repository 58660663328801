import React, {
	Dispatch, Reducer, useMemo, useReducer,
} from 'react';
import {
	SnackbarMessage,
	OptionsObject,
	SnackbarKey,
	useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import JsBarcode from 'jsbarcode';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import ProductPresentational from '../../components/Product/Product';
import {
	deleteProduct,
	getProducts,
	handleProductActiveStatus,
} from '../../services/product';
import { IProduct } from './ProductAssets';
import { ProductQueryParams } from '../../interfaces/ProductQueryParams';
import PrintLabelTemplate from '../../constants/printLabelTemplate';

enum ActionType {
    LOADING,
    PRODUCT,
	UPDATE_PRODUCT,
}

interface IState {
    count: number;
    loading: boolean;
    products: IProduct[];
    productsPages: number;
    productsPage: number;
    productsTake: number;
}

type TAction =
    | { type: ActionType.LOADING; payload: { loading: boolean } }
    | {
        type: ActionType.PRODUCT; payload: {
            products: IProduct[],
            count: number,
            productsPages: number,
            productsPage: number,
            productsTake: number,
        }
    }
	| { type: ActionType.UPDATE_PRODUCT; payload: { product: IProduct } };

interface IProductActions {
    setLoading(loading: boolean): void;
    getProducts(queryParams: ProductQueryParams): void;
	handleProductActiveStatus(id: string, active: boolean): void;
    handleDeleteProduct(id: string): void;
	handleEdit(id: string): void;
	printLabel(barCode: string): void;
}

const initialState: IState = {
	count: 0,
	loading: false,
	products: [],
	productsPages: 0,
	productsPage: 0,
	productsTake: 10,
};

let lastQueryParams: ProductQueryParams;

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.PRODUCT:
			return {
				...state,
				products: action.payload.products,
				count: action.payload.count,
				productsPages: action.payload.productsPages,
				productsPage: action.payload.productsPage,
				productsTake: action.payload.productsTake,
			};
		case ActionType.UPDATE_PRODUCT:
			return {
				...state,
				products: state.products.map((
					product,
				) => (product.id === action.payload.product.id ? action.payload.product : product)),
			};
		default:
			throw new Error();
	}
};

const ProductActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
	navigate: NavigateFunction,
): IProductActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getProducts(queryParams: ProductQueryParams, setLoading?: boolean) {
			actions.setLoading(setLoading !== false);
			const take = queryParams.take ?? 10;
			const params = { ...queryParams, skip: (queryParams?.skip || 0) * take };
			getProducts(params).then((response) => {
				dispatch({
					type: ActionType.PRODUCT,
					payload: {
						products: response.data.data,
						count: response.data.count,
						productsPages: response.data.count,
						productsPage: queryParams?.skip || 0,
						productsTake: take,
					},
				});
				actions.setLoading(false);
				lastQueryParams = {
					...queryParams,
				};
			});
		},
		handleDeleteProduct(id: string) {
			actions.setLoading(true);
			deleteProduct(id).then((response) => {
				enqueueSnackbar(response.data.message, { variant: 'success' });
			})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
				})
				.finally(() => {
					actions.setLoading(false);
					actions.getProducts(lastQueryParams, false);
				});
		},
		handleEdit(id: string) {
			navigate(`/edit/${id}`);
		},
		printLabel(barCode: string) {
			const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');

			JsBarcode(svgElement, barCode, {
				format: 'CODE128',
				displayValue: true,
				width: 2,
				height: 80,
				fontSize: 40,
			});

			const svgContent = svgElement.outerHTML;

			const templateString = PrintLabelTemplate({ svgContent });

			const printWindow = window.open('', '_blank');
			if (printWindow) {
				printWindow.document.open();
				printWindow.document.write(templateString);
				printWindow.document.close();
				printWindow.onload = () => printWindow.print();
			} else {
				enqueueSnackbar('Não foi possível abrir a janela de impressão.', {
					variant: 'error',
				});
			}
		},
		handleProductActiveStatus(id: string, active: boolean): void {
			handleProductActiveStatus(id, active)
				.then((response) => {
					enqueueSnackbar(response.data.message, { variant: 'success' });
					dispatch({ type: ActionType.UPDATE_PRODUCT, payload: { product: response.data.data } });
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu ao alterar o status do produto, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
				});
		},
	};

	return actions;
};

const Product = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(
		reducer,
		initialState,
	);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const actions = useMemo(
		() => ProductActions(
			dispatch,
			enqueueSnackbar,
			navigate,
		),
		[enqueueSnackbar, navigate],
	);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <ProductPresentational {...state} {...actions} />;
};

export default Product;
